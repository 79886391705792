import React, { useState } from "react";
import Select from "react-select";
import CloseIcon from "../../../assets/crossIcon.svg";
import { CircularProgress } from "@mui/material";
import { CaseServices } from "../../../services/CaseServices";
const AddNewDocument = ({
  specialityParam,
  procedureParam,
  specialityIdParam,
  procedureIdParam,
  getDefaultDocuments,
  setOpen,
}) => {
  const [documentName, setDocumentName] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [selectedSpeciality, setSelectedSpeciality] = useState(
    specialityParam ? specialityParam : null
  );
  const [selectedProcedure, setSelectedProcedure] = useState(
    procedureParam ? procedureParam : null
  );
  const [specialityId, setSpecialityId] = useState(
    specialityIdParam ? specialityIdParam : null
  );
  const [procedureId, setProcedureId] = useState(
    procedureIdParam ? procedureIdParam : null
  );

  const handleSaveDocument = async () => {
    setSubmitting(true);
    if (documentName && documentType) {
      const data = [
        {
          documentName: documentName,
          documentType: documentType,
          procedureId: documentType?.toLowerCase()?.includes("during")
            ? ""
            : procedureId,
          procedureName: selectedProcedure,
          specialityId: specialityId,
          specialityName: selectedSpeciality,
          required: true,
        },
      ];
      try {
        const response = await CaseServices.addDefaultDocuments(data);
        window.alert(response.data.message);
        setOpen(false);
        getDefaultDocuments();
      } catch (error) {
        window.alert(error);
      }
    } else {
      if (!documentName) {
        window.alert("Document Name Required");
      } else if (!documentType) {
        window.alert("Document Type required");
      }
    }
    setSubmitting(false);
  };
  return (
    <div className="fixed h-screen w-screen top-[54px] left-0 flex justify-center items-center bg-[#017C5780] z-20">
      <div className="relative h-fit w-[50%] bg-white rounded-[16px] p-8">
        <div className="absolute flex items-center gap-2  right-8 top-4 cursor-pointer ">
          <img
            src={CloseIcon}
            alt="close-icon"
            onClick={() => setOpen(false)}
          />
        </div>
        <div className=" bg-[#F9F9F9] p-4 rounded-[8px] mt-5">
          <div className="grid grid-cols-1 xl:grid-cols-2 mt-4 gap-4">
            <div>
              <div className="font-semibold mb-2">Document Name</div>
              <input
                type="text"
                placeholder="Enter Document Name"
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
              />
            </div>
            <div>
              <div className="font-semibold mb-2">Document Type</div>
              <Select
                name="documentType"
                options={[
                  {
                    value: "ADMISSIONDOCUMENT",
                    label: "ADMISSIONDOCUMENT",
                  },
                  {
                    value: "DURINGTREATMENTDOCUMENT",
                    label: "DURINGTREATMENTDOCUMENT",
                  },

                  {
                    value: "ONDISCHARGEDOCUMENT",
                    label: "ONDISCHARGEDOCUMENT",
                  },
                ]}
                defaultValue={documentType}
                getOptionLabel={(options) => {
                  return options["label"];
                }}
                getOptionValue={(options) => {
                  return options["value"];
                }}
                onChange={(item) => {
                  setDocumentType(item.value);
                }}
              />
            </div>
            <div>
              <div className="font-semibold mb-2">Speciality Name</div>
              <input
                type="text"
                value={specialityParam}
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                disabled
              />
            </div>
            <div>
              <div className="font-semibold mb-2">Speciality ID</div>
              <input
                type="text"
                placeholder="Enter Speciality Id"
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                defaultValue={specialityId}
                onChange={(e) => setSpecialityId(e.target.value)}
                disabled
              />
            </div>
            <div>
              <div className="font-semibold mb-2">Procedure Name</div>
              <input
                type="text"
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                defaultValue={procedureParam}
                disabled
              />
            </div>
            <div>
              <div className="font-semibold mb-2">Procedure ID</div>
              <input
                type="text"
                style={{ border: "1px solid #E4E4E4" }}
                className="w-full text-sm py-[8px] px-[12px] rounded-[8px]"
                defaultValue={procedureIdParam}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-4">
          {submitting ? (
            <CircularProgress />
          ) : (
            <div
              className="text-sm  text-white bg-[#017C57] font-semibold px-5 py-2 rounded-full cursor-pointer"
              onClick={handleSaveDocument}
            >
              Save Document
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewDocument;
