import { useEffect, useState } from "react";
import { ReportServices } from "../../../services/ReportServices";
import { CircularProgress } from "@mui/material";
import Select from "react-select";
import CloseIcon from "../../../assets/crossIcon.svg";
const AssignUserToCase = ({
  commandCenterStage,
  commandCenterStageId,
  isOpen,
  AllCases,
}) => {
  const [userList, setUserList] = useState([]);
  const [userId, setUserId] = useState(null);
  const [saving, setSaving] = useState(false);
  const handleSave = async () => {
    setSaving(true);
    if (userId) {
      try {
        const response = await ReportServices.assignCaseStage(
          commandCenterStageId,
          userId
        );
        console.log("assign stage response:", response);
        isOpen(null);
        await AllCases();
      } catch (error) {
        window.alert(error);
      }
    } else {
      window.alert("Please select user");
    }
    setSaving(false);
  };
  const getCaseStageUserList = async () => {
    try {
      const response = await ReportServices.getCaseStageUserList(
        commandCenterStage
      );
      console.log("user list response:", response?.data?.responsibleUsers);
      setUserList(response?.data?.responsibleUsers);
    } catch (error) {
      window.alert(error);
    }
  };
  useEffect(() => {
    getCaseStageUserList();
  }, []);

  return (
    <div className="fixed left-0 top-0 w-screen h-screen bg-[#017C5780] flex justify-center items-center z-[1200]">
      <div className="h-[30%] 2xl:h-[20%] w-[40%] 2xl:w-[30%] bg-white rounded-[16px]">
        <div className="flex justify-end mt-3 mr-4 cursor-pointer">
          <img src={CloseIcon} alt="close-icon" onClick={() => isOpen(null)} />
        </div>
        <div className="flex gap-3 px-4 mt-5">
          <div className="font-semibold text-[#191919]">
            Select User To Assign
          </div>
          <div className="w-[60%]">
            <Select
              options={userList}
              getOptionLabel={(options) => {
                return options["user"]["fullName"];
              }}
              getOptionValue={(options) => {
                return options["userId"];
              }}
              onChange={(item) => {
                console.log("selected user", item.user.fullName);
                setUserId(item.userId);
              }}
            />
          </div>
        </div>
        <div className="flex justify-center items-center mt-8">
          {saving ? (
            <CircularProgress />
          ) : (
            <div
              className="text-sm font-semibold text-white bg-[#017C57] uppercase px-12 py-2 rounded-full cursor-pointer"
              onClick={handleSave}
            >
              save
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignUserToCase;
