import { useState, useEffect } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import HospitalCustomPaginaion from "../../utils/HospitalPaginationUtil";
import CloseIcon from "../../assets/hospitalCloseIcon.svg";
import DownloadIcon from "../../assets/downloadIconGreen.svg";
import RotateLeft from "../../assets/rotate-left.svg";
import RotateRight from "../../assets/rotate-right.svg";
import Crop from "../../assets/crop.svg";
import RightArrow from "../../assets/rightArrowWhite.svg";

const ImagesGalleryHospitalPersona = ({
  data,
  setData,
  setOpen,
  selectedImage,
  view,
}) => {
  const [imageState, setImageState] = useState({
    image: "",
    brightness: 100,
    grayscale: 0,
    sepia: 0,
    saturate: 100,
    contrast: 100,
    hueRotate: 0,
    rotate: 0,
    vertical: 1,
    horizontal: 1,
  });
  const [selectedFilter, setSelectedFilter] = useState({
    name: "",
    maxValue: "",
  });
  const [crop, setCrop] = useState(null);
  const [details, setDetails] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(
    selectedImage ? selectedImage : 0
  );
  const filterValue = [
    {
      name: "brightness",
      maxValue: "200",
    },
    {
      name: "grayscale",
      maxValue: "200",
    },
    {
      name: "sepia",
      maxValue: "200",
    },
    {
      name: "saturate",
      maxValue: "200",
    },
    {
      name: "contrast",
      maxValue: "200",
    },
    {
      name: "hueRotate",
      maxValue: "200",
    },
  ];
  const imageCrop = () => {
    const canvas = document.createElement("canvas");
    const scaleX = details.naturalWidth / details.width;
    const scaleY = details.naturalHeight / details.height;
    canvas.width = crop.width;
    canvas.height = canvas.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      details,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    canvas.toBlob((blob) => {
      if (blob) {
        // Create a Blob URL and set it to state
        const blobUrl = URL.createObjectURL(blob);
        data[currentIndex].documentUrl = blobUrl;
        setData([...data]);
      }
    });
  };
  const applyFilter = () => {
    const canvas = document.createElement("canvas");

    // const img = document.getElementById("image"); // Access your image element

    // Set canvas dimensions to match the image
    canvas.width = details?.naturalWidth;
    canvas.height = details?.naturalHeight;
    const ctx = canvas.getContext("2d");

    // Apply the filter  by modifying the canvas context
    ctx.filter = `brightness(${imageState.brightness}%) sepia(${imageState.sepia}%) saturate(${imageState.saturate}%) contrast(${imageState.contrast}%) grayscale(${imageState.grayscale}%) hue-rotate(${imageState.hueRotate}deg)`;
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((imageState.rotate * Math.PI) / 180);
    ctx.scale(imageState.vertical, imageState.horizontal);

    // Draw the image onto the canvas
    ctx.drawImage(
      details,
      -canvas.width / 2,
      -canvas.height / 2,
      canvas.width,
      canvas.height
    );
    // Convert the canvas to a Blob
    canvas.toBlob((blob) => {
      if (blob) {
        // Create a Blob URL and set it to state
        const blobUrl = URL.createObjectURL(blob);
        data[currentIndex].documentUrl = blobUrl;
        setData([...data]);
      }
    });
    window.alert("Image Modified");
    setOpen(false);
  };
  const handleNextClick = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "ArrowRight") {
      handleNextClick();
    } else if (event.key === "ArrowLeft") {
      handlePrevClick();
    }
  };
  // useEffect(() => {
  //   window.addEventListener("keydown", handleKeyDown);
  //   // Cleanup on component unmount or modal close
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-[#017C5780] z-10">
      <div className="relative bg-white rounded-[16px] w-[80%] 2xl:w-[70%] h-[80%] p-4 xl:p-8">
        <div className="absolute flex items-center gap-2 top-0 right-0  cursor-pointer">
          <a
            href={data[currentIndex]?.documentUrl}
            download
            className="cursor-pointer"
          >
            <img src={DownloadIcon} alt="download-icon" />
          </a>
          <img
            src={CloseIcon}
            // height={30}
            // width={30}
            alt="close-icon"
            onClick={() => setOpen(false)}
          />
        </div>

        <div className="text-black flex justify-center h-[90%]">
          {!view && (
            <div className="w-[40%]">
              <div className="text-[28px] 2xl:text-[33px] -tracking-[7%] mb-1 px-2">
                Document Preview
              </div>
              <div
                style={{ borderTop: "1px solid #E8E8E8", marginBlock: "25px" }}
              />{" "}
              <div className="flex  flex-wrap  h-[30%] w-full  p-1">
                {filterValue?.map((filter, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{}}
                      className="w-full flex justify-between items-center"
                    >
                      <label className="w-[20%] text-[13px] 2xl:text-[15px] text-[#0B0B0B] -tracking-[5%] capitalize">
                        {filter.name}
                      </label>
                      <input
                        type="range"
                        name={filter.name}
                        value={imageState[filter.name]}
                        max={filter?.maxValue}
                        onChange={(e) =>
                          setImageState({
                            ...imageState,
                            [e.target.name]: e.target.value,
                          })
                        }
                        className="w-[80%] accent-[#017C71]"
                      />
                    </div>
                  );
                })}
              </div>
              {selectedFilter?.name && (
                <div className="flex items-center justify-between mt-2 px-2">
                  {" "}
                  <label className="text-black capitalize">
                    {selectedFilter.name}
                  </label>
                  <input
                    type="range"
                    name={selectedFilter.name}
                    value={imageState[selectedFilter.name]}
                    max={selectedFilter?.maxValue}
                    onChange={(e) =>
                      setImageState({
                        ...imageState,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                </div>
              )}
              <div
                style={{ borderTop: "1px solid #E8E8E8", marginBlock: "25px" }}
              />
              <div className="w-[130px] text-[13px] 2xl:text-[15px] -tracking-[5%] capitalize">
                <div className="flex justify-between items-center">
                  <div>Rotate left</div>
                  <img
                    src={RotateLeft}
                    alt="rotate-left"
                    onClick={() =>
                      setImageState({
                        ...imageState,
                        rotate: imageState.rotate - 90,
                      })
                    }
                  />
                </div>
                <div className="flex justify-between items-center mt-7">
                  <div>Rotate right</div>
                  <img
                    src={RotateRight}
                    alt="rotate-right"
                    onClick={() =>
                      setImageState({
                        ...imageState,
                        rotate: imageState.rotate + 90,
                      })
                    }
                  />
                </div>
                {/* <div className="flex justify-between items-center mt-7">
                <div>Crop</div>
                <img
                  src={Crop}
                  alt="crop"
                  onClick={() => {
                    crop ? imageCrop() : window.alert("Select Region To Crop");
                  }}
                />
              </div> */}
              </div>
              <div
                className="w-fit flex items-center gap-5 text-xs 2xl:text-sm text-white bg-[#017C71] leading-[21.7px] -tracking-[3%] mt-10 px-5 2xl:px-6 py-3 2xl:py-4 rounded-full cursor-pointer"
                onClick={applyFilter}
              >
                Save{" "}
                <img src={RightArrow} alt="right-arrow" onClick={applyFilter} />
              </div>
            </div>
          )}

          <div className="h-full w-[70%] flex justify-center ">
            {/* <ReactCrop
              style={{
                height: "100%",
                width: "70%",
                border: "1px solid #E4E4E4",
                overflowY: "scroll",
              }}
              crop={crop}
              onChange={(c) => setCrop(c)}
            >
              <div className="h-auto w-auto flex justify-center items-center ">
                <img
                  src={data[currentIndex]?.documentUrl}
                  onLoad={(e) => setDetails(e.currentTarget)}
                  id="image"
                  style={{
                    filter: `brightness(${imageState.brightness}%) sepia(${imageState.sepia}%) saturate(${imageState.saturate}%) contrast(${imageState.contrast}%) grayscale(${imageState.grayscale}%) hue-rotate(${imageState.hueRotate}deg)`,
                    transform: `rotate(${imageState.rotate}deg)`,
                    maxWidth: "100%", // Constrain the width to the container size
                    maxHeight: "100%", // Constrain the height to the container size
                  }}
                  className="object-contain h-full w-full shadow-2xl rounded-[8px]"
                  alt="document-img"
                />{" "}
              </div>
            </ReactCrop> */}
            <div className="h-auto w-auto flex justify-center items-center ">
              <img
                src={data[currentIndex]?.documentUrl}
                onLoad={(e) => setDetails(e.currentTarget)}
                id="image"
                style={{
                  filter: `brightness(${imageState.brightness}%) sepia(${imageState.sepia}%) saturate(${imageState.saturate}%) contrast(${imageState.contrast}%) grayscale(${imageState.grayscale}%) hue-rotate(${imageState.hueRotate}deg)`,
                  transform: `rotate(${imageState.rotate}deg)`,
                  maxWidth: "100%", // Constrain the width to the container size
                  maxHeight: "100%", // Constrain the height to the container size
                }}
                className="object-contain h-full w-full shadow-2xl rounded-[8px]"
                alt="document-img"
              />{" "}
            </div>
          </div>
        </div>
        <div className={`flex ${view ? "justify-center" : "justify-end"} mt-5`}>
          <div className="w-[60%] flex justify-center ">
            <HospitalCustomPaginaion
              page={currentIndex + 1}
              setPage={setCurrentIndex}
              totalPages={data.length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImagesGalleryHospitalPersona;
