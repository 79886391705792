import axios from "axios";
import { getAuthorizationToken } from "../config/user";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

function getNotifications(readStatus, limit, page, notificationCategory) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/user-notifications?readStatus=${readStatus}&limit=${limit}&page=${page}&category=${notificationCategory}`,
    headers: getAuthorizationToken(),
  });
}

function markNotificationReadUnread(notificationId, status) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/mark-read-unread-notification/${encodeURIComponent(
      JSON.stringify(notificationId)
    )}?read=${status}`,
    headers: getAuthorizationToken(),
  });
}
function markAllNotificationsReadUnread(status) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/mark-all-read-unread/?read=${status}`,
    headers: getAuthorizationToken(),
  });
}
function getDefaultNotificationList(searchKey, limit = 5, page = 1) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/default-notifications-list?searchKey=${searchKey}&limit=${limit}&page=${page}`,
    headers: getAuthorizationToken(),
  });
}
function updateDefaultNotifications(data) {
  return axios({
    method: "put",
    url: `${REACT_APP_API_URL}/update-default-notification`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
function manualNotificationSend(data) {
  return axios({
    method: "post",
    url: `${REACT_APP_API_URL}/manual-notification-send`,
    data: data,
    headers: getAuthorizationToken(),
  });
}
function getManualNotifications(searchKey, limit) {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/manual-notifications?searchKey=${searchKey}&limit=${limit}`,
    headers: getAuthorizationToken(),
  });
}
function getRoleList() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/role-name-list`,
    headers: getAuthorizationToken(),
  });
}
function getSubMasterStages(limit, category = "") {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/sub-master-stages?limit=${limit}&category=${category}`,
    headers: getAuthorizationToken(),
  });
}
function getUserNotificationCounts() {
  return axios({
    method: "get",
    url: `${REACT_APP_API_URL}/user-notification-counts`,
    headers: getAuthorizationToken(),
  });
}
export const NotificationServices = {
  getNotifications,
  markNotificationReadUnread,
  getDefaultNotificationList,
  updateDefaultNotifications,
  manualNotificationSend,
  getRoleList,
  getSubMasterStages,
  getManualNotifications,
  markAllNotificationsReadUnread,
  getUserNotificationCounts,
};
