import { useState, useEffect, useRef } from "react";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { OtpServices } from "../services/OtpServices";
import { HospitalServices } from "../services/HospitalServices";
import { CircularProgress } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { logOut } from "../config/user";
import CustomPagination from "../utils/paginationUtils";
import SearchIcon from "@mui/icons-material/Search";
import Select from "react-select";
import { useSelector } from "react-redux";
import "./TmsOtpList.scss";
const TmsOtpList = () => {
  const [otpList, setOtpList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [hospitalId, setHospitalId] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(15);
  let searchTimeRef = useRef();
  const { newOtpMessage } = useSelector((store) => store.notificationReducer);
  const handleSearch = (e) => {
    //debouncing structure
    if (searchTimeRef.current) {
      clearInterval(searchTimeRef.current);
    }
    searchTimeRef.current = setTimeout(() => {
      setSearchKey(e.target.value);
    }, 500);
  };

  const getDate = (dateString) => {
    const date = new Date(dateString);

    // Format the date as DD/MM/YYYY HH:MM
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-indexed
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };
  const getOtps = async () => {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() - 4);
    const smsDate = currentDate.toISOString();
    try {
      const res = await OtpServices.getOtpList(
        page,
        limit,
        searchKey,
        hospitalId,
        smsDate
      );
      setOtpList(res.data.data);
      setTotalPages(res.data.totalPages);
    } catch (error) {
      window.alert(error);
    }
  };
  const getHospitalList = async () => {
    try {
      const response = await HospitalServices.gethospitals("", 1, 1500);
      setHospitalList(response.data.list);
    } catch (error) {
      if (error?.response?.status === 401) {
        logOut();
      } else {
        window.alert(error);
      }
    }
  };
  useEffect(() => {
    getOtps();
  }, [page, searchKey, hospitalId, newOtpMessage]);
  useEffect(() => {
    getHospitalList();
  }, []);
  console.log("otp list:", otpList);
  return (
    <div className="tms-otp-box">
      <span className="path_line">
        <WidgetsOutlinedIcon style={{ height: "24px" }} /> &nbsp; &nbsp;/ &nbsp;
        &nbsp; Dashboard &nbsp; &nbsp;/ &nbsp; &nbsp; Digital Medco OTP
      </span>
      <div className="heading">
        Digital Medco OTP
        <div className="text-[16px] flex items-center gap-4">
          <Select
            name="hospitalId"
            isClearable
            options={hospitalList}
            getOptionLabel={(options) => {
              return options["name"];
            }}
            getOptionValue={(options) => {
              return options["id"];
            }}
            onChange={(item) => {
              setPage(1);
              item ? setHospitalId(item.id) : setHospitalId("");
            }}
            value={hospitalList.filter((item) => item.id === hospitalId)}
            placeholder="FILTER HOSPITAL WISE"
          />
          <div className="flex gap-2 items-center py-2 px-4 bg-white rounded-[8px]">
            {" "}
            <SearchIcon className="text-xl" />
            <input
              type="text"
              className="outline-none border-none"
              placeholder="Search Otp"
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          border: "1px solid #E7E7E7",
          marginBottom: "20px",
        }}
      />
      {isLoading ? (
        <div className="flex justify-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className={`grid grid-cols-6  text-[#797979] text-[18px] px-8`}>
            <div>
              <div className="text-[#191919] font-semibold mb-4">
                Hospital Name
              </div>
            </div>
            <div>
              <div className="text-[#191919] font-semibold mb-4">
                {" "}
                TMS Mobile Number
              </div>{" "}
            </div>
            <div>
              <div className="text-[#191919] font-semibold mb-4"> OTP Type</div>{" "}
            </div>
            <div>
              <div className="text-[#191919] font-semibold mb-4"> OTP</div>{" "}
            </div>
            <div>
              <div className="text-[#191919] font-semibold mb-4">
                {" "}
                SMS Received Time
              </div>
            </div>
            <div>
              <div className="text-[#191919] font-semibold mb-4">
                {" "}
                Command Center Received Time
              </div>
            </div>
          </div>
          {otpList && otpList.length > 0 ? (
            otpList?.map((otp, idx) => {
              return (
                <div
                  key={idx}
                  className={`py-6 px-8 rounded-[16px] ${
                    idx % 2 === 0 ? "bg-white" : "bg-[#F9F9F9]"
                  }`}
                >
                  <div
                    className={`grid grid-cols-6  text-[#797979] text-[18px] `}
                  >
                    <div>
                      <div>{otp?.hospital?.name || "--"}</div>
                    </div>
                    <div>
                      <div>{otp?.mobileNo || "--"}</div>
                    </div>
                    <div>
                      <div>{otp?.otpType || "--"}</div>
                    </div>
                    <div>
                      <div className="flex items-center gap-1">
                        {otp?.otp || "--"}{" "}
                        {otp?.otp && (
                          <ContentCopyIcon
                            className="cursor-pointer active:bg-slate-400 mr-1"
                            fontSize="12px"
                            onClick={() =>
                              navigator.clipboard.writeText(otp?.otp)
                            }
                          />
                        )}
                      </div>
                    </div>
                    <div>
                      <div>{otp?.smsDate ? getDate(otp?.smsDate) : "--"}</div>
                    </div>
                    <div>
                      <div>{otp?.createdAt ? getDate(otp?.smsDate) : "--"}</div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="text-xl flex items-center justify-center mt-5 font-semibold h-[300px]">
              {" "}
              No Otps Found
            </div>
          )}
        </div>
      )}

      <div className="flex justify-center items-center mt-3">
        <CustomPagination
          totalPages={totalPages}
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  );
};

export default TmsOtpList;
